import React, { Component, Fragment } from 'react';
import { FormControl, Form } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import RequiredLabel from '../../../global_components/RequiredLabel';
import { getValueFromInputData } from '../../../helpers/objects';

export class ZipPostal extends Component {
	constructor(props) {
		super(props);
		this.zipPostalInput = React.createRef();
	}
	componentDidMount() {
		this?.zipPostalInput?.current?.focus();
	}
	render() {
		const { inputData, business } = this.props;
		return (
			<Fragment>
				<Header head="Location?" subHead="Let's see if we're in your area. Please enter your zip or postal code." />
				<div className="d-flex justify-content-center">
					<Form.Group className="mb-0">
						<Form.Label className="label">{business.is_canadian ? 'Postal code' : 'Zip code'} <RequiredLabel /></Form.Label>
						<FormControl
							size="sm"
							defaultValue={ getValueFromInputData(inputData, 'customer-zip_postal') }
							name="customer-zip_postal"
							ref={ this.zipPostalInput }
							onChange={ (event) => { this.props.inputChange({ event }); } }
						/>
					</Form.Group>
				</div>
			</Fragment>
		);
	}
}

export default ZipPostal;
