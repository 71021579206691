export const inputData = {
	customer: {
		first: '',
		last: '',
		email: '',
		address: '',
		city: '',
		state: 'default',
		zip_postal: '',
		phone1: '',
		phone2: '',
		payment_type: 1,
	},
	payer: {
		organization: '',
		first: '',
		last: '',
		email: '',
		address: '',
		city: '',
		state: 'default',
		zip_postal: '',
		phone1: '',
		phone2: '',
	},
	extended_warranty: {
		provider: '',
		contract_number: '',
		purchase_order_number: '',
	},
	appointment: {
		date: null,
		time: 'default',
		zone_number: null,
		virtual: false,
		agreed_to_terms: false,
	},
	machine: {
		make: 'default',
		type: 'default',
		model_number: '',
		serial_number: '',
		dealer: 'default',
		purchase_date: '',
		problem_description: '',
		extra_notes: '',
	},
};

export const scenarios = {
	regular_schedule_adds: ['zip_postal', 'date_time', 'customer_information', 'machine_information', 'payment_type', 'summarize', 'finalize'],
	original_warranty_adds: ['zip_postal', 'date_time', 'customer_information', 'machine_information', 'summarize', 'finalize'],
	extended_warranty_adds: ['zip_postal', 'date_time', 'customer_information', 'machine_information', 'extended_warranty', 'summarize', 'finalize'],
	third_party_adds: ['zip_postal', 'date_time', 'customer_information', 'machine_information', 'third_party_payer', 'summarize', 'finalize'],
	direct_to_zip_adds: ['zip_postal', 'date_time', 'customer_information', 'machine_information', 'payment_type', 'summarize', 'finalize'],

	regular_schedule: ['payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'payment_type', 'summarize', 'finalize'],
	original_warranty: ['payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'summarize', 'finalize'],
	extended_warranty: ['payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'extended_warranty', 'summarize', 'finalize'],
	third_party: ['payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'third_party_payer', 'summarize', 'finalize'],
	direct_to_zip: ['payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'payment_type', 'summarize', 'finalize'],

	// actual step orders needed
	// regular_schedule: ['initial', 'payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'payment_type', 'summarize', 'finalize'],
	// direct_to_zip: ['initial', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'payment_type', 'summarize', 'finalize'],

	// original_warranty: ['initial', 'payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'summarize', 'finalize'],
	// extended_warranty: ['initial', 'payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'extended_warranty', 'summarize', 'finalize'],
	// third_party: ['initial', 'payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'third_party_payer', 'summarize', 'finalize'],
};


export const states = {
	AL: 'Alabama',
	AK: 'Alaska',
	AZ: 'Arizona',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	DC: 'District Of Columbia',
	FL: 'Florida',
	GA: 'Georgia',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PA: 'Pennsylvania',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
};

export const provinces = {
	AB: 'Alberta',
	BC: 'British Columbia',
	MB: 'Manitoba',
	NB: 'New Brunswick',
	NL: 'Newfoundland and Labrador',
	NS: 'Nova Scotia',
	ON: 'Ontario',
	PE: 'Prince Edward Island',
	QC: 'Quebec',
	SK: 'Saskatchewan',
	NT: 'Northwest Territories',
	NU: 'Nunavut',
	YT: 'Yukon',
};