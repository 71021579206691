import React, { Component, Fragment } from 'react';
import { Col, Form } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import { getValueFromInputData } from '../../../helpers/objects';
import { states, provinces } from '../../../assets/objects';
import RequiredLabel from '../../../global_components/RequiredLabel';
import InputMask from 'react-input-mask';

export class CustomerInformation extends Component {
	constructor(props) {
		super(props);
		this.firstNameInput = React.createRef();
	}
	componentDidMount() {
		this.firstNameInput.current.focus();
	}
	renderStateOrProvinceOptions = () => {
		const { business } = this.props;
		if (business.is_canadian) {
			const provincesArray = Object.keys(provinces);
			return provincesArray.map(province => {
				return <option value={ province } key={ province }>{ provinces[province] }</option>;
			});
		} else {
			const statesArray = Object.keys(states);
			return statesArray.map(state => {
				return <option value={ state } key={ state }>{ states[state] }</option>;
			});
		}
	};
	render() {
		const { inputData, business } = this.props;
		return (
			<Fragment>
				<Header head="About you." subHead="Now that we know we can make an appointment, let's get to know you." />
				<div>
					<Form>
						<Form.Row>
							<Col>
								<Form.Label className="label">First name <RequiredLabel /></Form.Label>
								<Form.Control ref={ this.firstNameInput } size="sm" defaultValue={ getValueFromInputData(inputData, 'customer-first') } name="customer-first" onChange={ (event) => { this.props.inputChange({ event }); } } maxLength="50" />
							</Col>
							<Col>
								<Form.Label className="label">Last name <RequiredLabel /></Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'customer-last') } name="customer-last" onChange={ (event) => { this.props.inputChange({ event }); } } maxLength="50" />
							</Col>
						</Form.Row>
						<Form.Row>
							<Col>
								<Form.Label className="label">Email address <RequiredLabel /></Form.Label>
								<Form.Control type="email" size="sm" defaultValue={ getValueFromInputData(inputData, 'customer-email') } name="customer-email" onChange={ (event) => { this.props.inputChange({ event }); } } />
							</Col>
							<Col>
								<Form.Label className="label">Address <RequiredLabel /></Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'customer-address') } name="customer-address" onChange={ (event) => { this.props.inputChange({ event }); } } />
							</Col>
						</Form.Row>
						<Form.Row>
							<Col>
								<Form.Label className="label">City <RequiredLabel /></Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'customer-city') } name="customer-city" onChange={ (event) => { this.props.inputChange({ event }); } } maxLength="50" />
							</Col>
							<Col>
								<Form.Label className="label">{ business.is_canadian ? 'Province' : 'State' } <RequiredLabel /></Form.Label>
								<Form.Control size="sm" as="select" defaultValue={ getValueFromInputData(inputData, 'customer-state') } name="customer-state" onChange={ (event) => { this.props.inputChange({ event }); } } >
									<option disabled value="default">Select</option>
									{ this.renderStateOrProvinceOptions() }
								</Form.Control>
							</Col>
						</Form.Row>
						<Form.Row>
							<Col>
								<Form.Label className="label">Phone 1 <RequiredLabel /></Form.Label>
								<Form.Control type="tel" as={ InputMask } mask="(999) 999-9999" size="sm" defaultValue={ getValueFromInputData(inputData, 'customer-phone1') } name="customer-phone1" onChange={ (event) => { this.props.inputChange({ event }); } } />
							</Col>
							<Col>
								<Form.Label className="label">Phone 2</Form.Label>
								<Form.Control type="tel" as={ InputMask } mask="(999) 999-9999" size="sm" defaultValue={ getValueFromInputData(inputData, 'customer-phone2') } name="customer-phone2" onChange={ (event) => { this.props.inputChange({ event }); } } />
							</Col>
						</Form.Row>
					</Form>
				</div>
			</Fragment>
		);
	}
}

export default CustomerInformation;
