import React, { Component, Fragment } from 'react';
import Header from '../../../global_components/Header';
import { Table } from 'react-bootstrap';
import { formatPhoneReadable, formatTimeFrameReadable } from '../../../helpers/formatting';


export class Summarize extends Component {
	renderScenarioRows = () => {
		const { scenario, inputData } = this.props;
		if (scenario === 'regular_schedule') {
			return (
				<tr>
					<td><b>Location/Payer</b></td>
					<td>
						{ `${inputData.customer.first} ${inputData.customer.last}` }<br />
						{ inputData.customer.email }<br />
						{ formatPhoneReadable(inputData.customer.phone1) }<br />
						{ inputData.customer.phone2 && (<Fragment>{formatPhoneReadable(inputData.customer.phone2)}<br /></Fragment>) }
						{ inputData.customer.address }<br />
						{ `${inputData.customer.city}, ${inputData.customer.state} ${inputData.customer.zip_postal}` }
					</td>
				</tr>
			);
		} else if (scenario === 'original_warranty') {
			return (
				<Fragment>
					<tr>
						<td><b>Location</b></td>
						<td>
							{ `${inputData.customer.first} ${inputData.customer.last}` }<br />
							{ inputData.customer.email }<br />
							{ formatPhoneReadable(inputData.customer.phone1) }<br />
							{ inputData.customer.phone2 && (<Fragment>{formatPhoneReadable(inputData.customer.phone2)}<br /></Fragment>) }
							{ inputData.customer.address }<br />
							{ `${inputData.customer.city}, ${inputData.customer.state} ${inputData.customer.zip_postal}` }
						</td>
					</tr>
					<tr>
						<td><b>Payer</b></td>
						<td>
							{ inputData.machine.make } (manufacturer warranty)
						</td>
					</tr>
				</Fragment>
			);
		} else if (scenario === 'extended_warranty') {
			return (
				<Fragment>
					<tr>
						<td><b>Location</b></td>
						<td>
							{ `${inputData.customer.first} ${inputData.customer.last}` }<br />
							{ inputData.customer.email }<br />
							{ formatPhoneReadable(inputData.customer.phone1) }<br />
							{ inputData.customer.phone2 && (<Fragment>{formatPhoneReadable(inputData.customer.phone2)}<br /></Fragment>) }
							{ inputData.customer.address }<br />
							{ `${inputData.customer.city}, ${inputData.customer.state} ${inputData.customer.zip_postal}` }
						</td>
					</tr>
					<tr>
						<td><b>Payer</b></td>
						<td>
							{ inputData.extended_warranty.provider } (extended warranty)<br />
							Claim #{ inputData.extended_warranty.contract_number && (<Fragment>{inputData.extended_warranty.contract_number}<br /></Fragment>) }
							{ inputData.extended_warranty.purchase_order_number && `P.O. #${inputData.extended_warranty.purchase_order_number}` }
						</td>
					</tr>
				</Fragment>
			);
		} else if (scenario === 'third_party') {
			return (
				<Fragment>
					<tr>
						<td><b>Location</b></td>
						<td>
							{ `${inputData.customer.first} ${inputData.customer.last}` }<br />
							{ inputData.customer.email }<br />
							{ formatPhoneReadable(inputData.customer.phone1) }<br />
							{ inputData.customer.phone2 && (<Fragment>{formatPhoneReadable(inputData.customer.phone2)}<br /></Fragment>) }
							{ inputData.customer.address }<br />
							{ `${inputData.customer.city}, ${inputData.customer.state} ${inputData.customer.zip_postal}` }
						</td>
					</tr>
					<tr>
						<td><b>Payer</b></td>
						<td>
							{ inputData.payer.organization && (<Fragment>{inputData.payer.organization}<br /></Fragment>) }
							{ `${inputData.payer.first} ${inputData.payer.last}` }<br />
							{ inputData.payer.email }<br />
							{ formatPhoneReadable(inputData.payer.phone1) }<br />
							{ inputData.payer.phone2 && (<Fragment>{formatPhoneReadable(inputData.payer.phone2)}<br /></Fragment>) }
							{ inputData.payer.address }<br />
							{ `${inputData.payer.city}, ${inputData.payer.state} ${inputData.payer.zip_postal}` }
						</td>
					</tr>
				</Fragment>
			);
		}
	};

	render() {
		const { inputData } = this.props;
		const { appointment } = inputData;
		const { DateTime } = require('luxon');
		const dateTime = DateTime.fromISO(appointment.date);
		const readableDate = dateTime.toLocaleString(DateTime.DATE_HUGE);
		const readableTimeFrame = formatTimeFrameReadable(appointment.time, 'description');
		return (
			<Fragment>
				<Header head="Let's review." subHead="If everything looks correct, click Schedule to finalize your appointment." />
				<Table className="summary" bordered size="sm">
					<tbody>
						<tr>
							<td style={{ width: '30%' }}><b>Date/Time</b></td>
							<td>
								{`${readableDate} ${readableTimeFrame !== 'default' ? readableTimeFrame : ''}`}
							</td>
						</tr>
						{ this.renderScenarioRows() }
						<tr>
							<td style={{ width: '30%' }}><b>Machine</b></td>
							<td>
								{ `${inputData.machine.make} ${inputData.machine.type}` }
							</td>
						</tr>
						<tr>
							<td style={{ width: '30%' }}><b>Problem description</b></td>
							<td>
								{ inputData.machine.problem_description }
							</td>
						</tr>
					</tbody>
				</Table>
			</Fragment>
		);
	}
}

export default Summarize;
